import { translate } from '@/core/localization/translate';
export const {
  title,
  subtitle1,
  text1,
  subtitle2,
  text2,
  subtitle3,
  text3
} = translate({
  "title": "Le plus grand magasin en ligne d'Europe de brico, maison & jardin",
  "subtitle1": "Des millions de produits",
  "text1": "62 fois plus qu'en magasin, \xE7a en fait du choix",
  "subtitle2": "Livraison chez vous ou en point relais",
  "text2": "\xC7a, c'est vous qui voyez",
  "subtitle3": "Des centaines d'experts en ligne",
  "text3": "Aussi experts que Macgyver, plus dispos que votre ophtalmo"
});